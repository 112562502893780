import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import config from "../../config";
import useHttp from '../../hooks/useHttp';
import { GTIWebDataGrid, Typography } from '@gti-controls/web';
import { SettingsContext } from "../../providers/settings";
import { AuthContext } from "../../providers/auth";
import homeStyles from "../../common/styles/Home";
import ReactTooltip from 'react-tooltip';

const GridView = ({
  url,
  newApi, // PROVISIONAL
  method,
  body,
  dataKey,
  columns,
  getRowId,
  pageSize,
  firstRowSelectedOnLoad,
  deleteDataOnError,
  showCrudButtons, //Mostrar botones CRUD
  showUpDownButtons, //Mostrar botones para mover registros arriba/abajo
  showMoreActionsSelector, //Mostrar selector de acciones adicionales
  showBasicActionButtons, //Mostrar botones de imprimir, descargar y refrescar información
  showSearchTextField, //Mostrar text-field de busqueda
  searchTextFieldAutoFocus,
  additionalComponent, //Componentes adicionales
  gridTitle,
  refresh,
  exportedFileName,
  onCellClick,
  onActionClick,
  onSelectionChange,
  keepSelection,
  onError,
  onUp,
  onDown,
  onLoaded,
  hideFooter,
  dataSource,
  actionId,
  isLoadingDataSource,
  hideRowsPerPage,
  renglonHeaderMercado,
  rowHeight,
  disableSelectionOnClick,
  filterColumns
}) => {
  const classes = homeStyles();
  const history = useHistory();
  const location = useLocation();
  const { isLoading, error, sendRequest: fetchData } = useHttp();
  const { handleActionSelected, handleLoadingContainerChange } = useContext(SettingsContext);
  const { state: authContext } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [actions
    , setActions] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [columnsFilter, setColumnsFilter] = useState(columns);

  /** Tipos de orden */
  const orderTypes = {
    up: 1,
    down: 2
  };

  const [inheritedRow, setInheritedRow] = useState(null);

  /** Funcion al cargar el contenedor */
  useEffect(() => {
    handleLoadingContainerChange(true); getActions();
    // eslint-disable-next-line
  }, []);
  /** Funcion al recargar información */
  // eslint-disable-next-line
  useEffect(() => { if (isLoading) { handleLoadingContainerChange(true); } }, [isLoading]);
  /** Funcion useEffect cuando url cambia */
  // eslint-disable-next-line
  useEffect(() => { if (url) { getData(); } }, [url]);
  /** Funcion useEffect cuando body cambia */
  // eslint-disable-next-line
  useEffect(() => { if (url && body) { getData(); } }, [body]);
  /** Funcion useEffect cuando refresh cambia */
  // eslint-disable-next-line
  useEffect(() => { if (refresh !== undefined && refresh !== null) { getData(); } }, [refresh]);
  /** Funcion useEffect cuando ocurrió un error al traer información */
  // eslint-disable-next-line
  useEffect(() => { if (error) { handleLoadingContainerChange(false); } }, [error]);
  /** Funcion useEffect para asignar dataSource  */
  // eslint-disable-next-line
  useEffect(() => { if (dataSource) { getData(); } }, [dataSource]);
  /** Funcion useEffect cuando se recarga dataSource  */
  // eslint-disable-next-line
  useEffect(() => { if (isLoadingDataSource) { handleLoadingContainerChange(true); } }, [isLoadingDataSource]);
  /** Funcion useEffect cuando se selecciona un selectedRow  */
  // eslint-disable-next-line
  useEffect(() => { if (onSelectionChange) { onSelectionChange(selectedRow); if (keepSelection) { setInheritedRow(selectedRow.id) } } }, [selectedRow]);

  useEffect(() => {
    if (error) {
      if (deleteDataOnError) { setData([]); if (onError) { onError({ error: error, data: [] }) } }
      handleLoadingContainerChange(false);
    }
    // eslint-disable-next-line
  }, [error]);
  /** Funcion getData */
  const getData = () => {
    if (url) {
      // Información del grid
      fetchData({
        url: `${(newApi) ? config.ADMIN_API_URL : config.API_URL}${url}`,
        method: method ? method : null,
        body: body ? body : null,
      }, (data) => {
        if (filterColumns) {
          const filteredColumns = columns.filter(col => data[0].hasOwnProperty(col.field));
          setColumnsFilter(filteredColumns);
        }
        setData(dataKey ? data[dataKey] : data);
        onFinishLoad(data);
      });
    }
    else {
      setData(dataSource);
      onFinishLoad(dataSource);
    }
  };

  const onFinishLoad = (data) => {
    if (onLoaded) { onLoaded(data) }
    setTimeout(() => {
      handleLoadingContainerChange(false);
    }, 500);
  }

  const getVerboID = () => {
    return actionId ? actionId : location.state?.sub ? (location.state?.sub.verboID || '') : (location.state?.verb) ? (location.state.verb.VerboID || '') : '';
  }
  const getActions = () => {
    //Acciones
    if (showMoreActionsSelector && getVerboID()) {
      fetchData({
        url: `${config.API_URL}rights/verbos/${getVerboID()}/acciones/` + authContext.user.sesionId,
      }, (data) => {
        setActions(data)
      });

    }
  };

  const handleActionClick = (action) => {
    const actionResult = (onActionClick) ? onActionClick(selectedRow, action) : null;
    const keep = Boolean(actionResult) && !(actionResult || {}).data;
    if (!keep) {
      // Acción DEFAULT: redirección (Para evitar redirección es necesario regresar TRUE en la implementación de onActionClick)
      // Tambien puede regresarse un objeto { data: { } }, para no utilizar la selección del grid, sino otro dato del container
      handleActionSelected(action); //Settings Context
      history.push({
        pathname: `${location.pathname}/${action.verbo.className.replace('#', '')}`,
        state: {
          verb: location.state.verb,
          action: action,
          data: (actionResult || {}).data ? actionResult.data : selectedRow
        }
      });
    }
  };

  /** Constante que envia el evento de clic de botones 
   * de ordenamiento al contenedor
   */
  const handleOrderClick = (action, orderType) => {
    switch (orderType) {
      case orderTypes.up:
        if (onUp) { onUp(selectedRow, action, orderType) }
        break;
      case orderTypes.down:
        if (onDown) { onDown(selectedRow, action, orderType) }
        break;
      default:
        break;
    }
  };

  const handleCellDoubleClick = () => {
    const actionUpdate = actions.find(a => a['boton'] === 2);
    if (actionUpdate) {
      handleActionClick(actionUpdate);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography align="center" variant="h6" className={classes.gridTitle}>
        {gridTitle}
      </Typography>
      <>
        {
          renglonHeaderMercado ?
            <div style={{
              marginBottom: '14px',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: '500',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.5',
              letterSpacing: 'normal',
              color: '#373740'
            }}>
              <div style={{ marginRight: '18px', display: 'inline' }}>
                <strong style={{ marginRight: '5px' }}>Nivel:</strong>{renglonHeaderMercado.Nivel}
              </div>

              <div style={{ marginRight: '18px', display: 'inline' }}>
                <strong style={{ marginRight: '5px' }}>Puesto Referencia:</strong>{renglonHeaderMercado.PuestoReferencia}
              </div>

              <div style={{
                display: 'inline',
                color: '#0b5c96',
                fontWeight: '500',
                cursor: 'pointer'
              }}
                data-tip={renglonHeaderMercado.TopEjemplos} data-for="topPuestos"
              >
                Ver ejemplos de puestos
              </div>
              <ReactTooltip id="topPuestos"
                place="bottom"
                effect="solid"
                html={true}
                className
              ></ReactTooltip>
            </div> : null
        }
      </>
      <GTIWebDataGrid
        rows={data}
        columns={filterColumns ? columnsFilter : columns}
        exportedFileName={exportedFileName}
        getRowId={getRowId}
        pageSize={pageSize || 25}
        headerHeight={36}
        rowHeight={rowHeight || 36}
        disableExtendRowFullWidth={true}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={disableSelectionOnClick}
        firstRowSelectedOnLoad={keepSelection && inheritedRow ? false : firstRowSelectedOnLoad === false ? false : true}
        showCrudButtons={showCrudButtons}
        showUpDownButtons={showUpDownButtons}
        showMoreActionsSelector={showMoreActionsSelector}
        showBasicActionButtons={showBasicActionButtons}
        showSearchTextField={showSearchTextField}
        searchTextFieldAutoFocus={searchTextFieldAutoFocus}
        hideFooterSelectedRowCount={true}
        actions={actions}
        actionDisplayedValue={'texto'}
        actionButtonValue={'boton'}
        actionDisabledValue={'select'}
        onSelectionChange={setSelectedRow}
        onAction={handleActionClick}
        onPrint={() => { window.print(); }}
        onReload={getData}
        onCellClick={onCellClick}
        onCellDoubleClick={handleCellDoubleClick}
        onLoaded={onLoaded}
        onUp={(e) => handleOrderClick(e, orderTypes.up)}
        onDown={(e) => handleOrderClick(e, orderTypes.down)}
        hideFooter={hideFooter}
        inheritedRow={inheritedRow}
        hideRowsPerPage={hideRowsPerPage}
      />
      {additionalComponent}
    </div>
  )
}
export default GridView;

import React, { useState, useEffect } from "react";
import logo from "../../images/logo-masorden-gris-small.svg";
import loginImage from "../../images/img-login.svg";
import LoginLayout from "../../layouts/login-layout";
import loginStyles from "../../common/styles/Login";
import LoginAlert from "../../components/login-alert";
import { GTIWebTextField, GTIWebButton, Paper, Grid, Error, CheckCircle, CircularProgress, ArrowBack, CheckCircleOutline } from "@gti-controls/web";
import useHttpLogin from "../../hooks/useHttpLogin";
import { useHistory, useLocation } from "react-router-dom";
import useInputValidator, { useInputValidatorEmails } from "../../hooks/useInputValidator";

export const ForgotPassword = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = loginStyles();
    const { error, isLoading, confirmUser, forgotPass, confimrPass } = useHttpLogin();
    const { validateInputEmail } = useInputValidatorEmails();
    const { validateInput: validateInputPasswords } = useInputValidator();
    const [formData, setFormData] = useState({
        usernameEmail: "",
        userState: null,
        userLegend: "Entra con el dato que tienes registrado",
        userIcon: null,

        password: "",
        passwordState: null,
        passwordLegend: "Debe contener mínimo 8 caracteres con: 1 mayúscula y 1 minúscula",

        passwordConfirm: "",
        confirmState: null,
        confirmLegend: ""
    });
    const [recoveryInfo, setRecoveryInfo] = useState({
        email: '',
        code: '',
        visible: true,
        tile: "Restablece tu contraseña",
        buttonText: "Recuperar",
        type: "1"
    });
    const [sendRecoveryView, setSendRecoveryView] = useState(false);
  
    const typeProc = {
        confirmacion: "1",
        recupera: "2"
    };

    useEffect(() => {
        if (location.search) {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            const email = params.get('email');
            const type = params.get('type');

            setRecoveryInfo({
                email: email,
                code: code,
                visible: (email && code),
                tile: "Cambia tu contraseña",
                buttonText: "Cambiar contraseña",
                type: type
            });
        } else {
            setRecoveryInfo({
                email: '',
                code: '',
                visible: false,
                tile: "Restablece tu contraseña",
                buttonText: "Recuperar"
            });
        }
    }, [location]);

    const redirectLogin = () => {
        history.replace({
            pathname: '/Login'
        });
    };

    const handleSubmit = async (e) => {
        if (recoveryInfo.visible) {
            await confirmRecovery(e);
        } else {
            await sendRecovery(e);
        }
    };

    const confirmRecovery = async (e) => {
        e.preventDefault();
        if (validateForm()) {

            const resp = typeProc.recupera === recoveryInfo.type ? await confimrPass(recoveryInfo.email, formData.password, recoveryInfo.code) : await confirmUser(recoveryInfo.email, formData.password, recoveryInfo.code);
            if (resp?.data === recoveryInfo.email) {
                initValues();
                redirectLogin();
            }
            
        }
    };

    const sendRecovery = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const response = await forgotPass(formData.usernameEmail);

            if ( response?.exitoso === false ) {
                setFormData({
                    ...formData,
                    userLegend: response.data.errorDescription,
                    userState: "error",
                    userIcon: <Error style={{ color: "#b71c1c" }} />
                });

                return;
            }

            initValues();
            setSendRecoveryView(true);
        }
    };

    const validateForm = () => {
        let fromInfo;

        if (recoveryInfo.visible) {
            fromInfo = formData;
            fromInfo = {
                ...fromInfo,
                passwordLegend: formData.password === "" ? "Nueva contraseña es obligatorio" : formData.passwordLegend,
                passwordState: formData.password === "" ? "error" : formData.passwordState,
                confirmLegend: formData.passwordConfirm === "" ? "Confirmar contraseña es obligatorio" : formData.confirmLegend,
                confirmState: formData.passwordConfirm === "" ? "error" : formData.confirmState
            };
            setFormData(fromInfo);

            return !(fromInfo.passwordState === "error" || fromInfo.confirmState === "error");
        } else {
            fromInfo = formData;
            fromInfo = {
                ...fromInfo,
                userLegend: formData.usernameEmail === "" ? "Correo electrónico es un campo obligatorio" :  formData.userLegend, 
                userState: formData.usernameEmail === "" ? "error" : formData.userState,
                userIcon: formData.usernameEmail === "" ? <Error style={{ color: "#b71c1c" }} /> : formData.userIcon,
            };
            setFormData(fromInfo);

            return fromInfo.userState !== "error"; 
        }
    };

    /** Constante que maneja los cambios en los campos */
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    };

    const validateInput = () => {
        const handleValidatorEmail = (objectResponse) => {
            if (objectResponse) {
                setFormData({
                    ...formData,
                    userLegend: "Entra con el dato que tienes registrado",
                    userState: "success",
                    userIcon: <CheckCircle style={{ color: "#108043" }} />
                });
            } else {
                setFormData({
                    ...formData,
                    userLegend: 'Correo electrónico incompleto. Ejemplo: ejemplo@correo.com',
                    userState: "error",
                    userIcon: <Error style={{ color: "#b71c1c" }} />
                });
            }
        }

        validateInputEmail({ text: formData.usernameEmail }, handleValidatorEmail);
    };

    const validatePasswords = (e) => {
        const handleValidatePassword = (objectResponse) => {
            if (objectResponse.isValid) {
                setFormData({
                    ...formData,
                    passwordLegend: "Debe contener mínimo 8 caracteres con: 1 mayúscula y 1 minúscula",
                    passwordState: null,
                });
            } else {
                setFormData({
                    ...formData,
                    passwordLegend: objectResponse.text,
                    passwordState: "error",
                });
            }
        };

        if (e.target.name === "password") {
            validateInputPasswords({ text: formData.password, isPassword: true }, handleValidatePassword);
        }
        
        if ((formData.password !== '' && formData.passwordConfirm !== '') && formData.password !== formData.passwordConfirm) {
            setFormData({
                ...formData,
                confirmLegend: 'Las contraseñas no coinciden.',
                confirmState: "error",
            });
        } else if ((formData.password !== '' && formData.passwordConfirm !== '') && formData.password === formData.passwordConfirm) {
            setFormData({
                ...formData,
                confirmLegend: '',
                confirmState: null,
            });
        }
    };


    const FormRecovery =   <>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        { error ? <LoginAlert severity={error ? error.error ? "error" : "warning" : "error"} error={error} /> : null }
                    </Grid>
                    <Grid item xs={12}>
                        <GTIWebTextField
                            name="usernameEmail"
                            type="login"
                            disabled={isLoading}
                            value={formData.usernameEmail}
                            labelText={"Correo electrónico"}
                            placeholder={"Escribe tu correo electrónico corporativo"}
                            width="100%"
                            autoFocus={true}
                            legendText={formData.userLegend}
                            icon={formData.userIcon}
                            state={formData.userState}
                            onChange={handleChange}
                            onBlur={validateInput}
                            maxLength={255}
                        />
                    </Grid>
                </Grid>
            </>
   

    const ChangePassword = <>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        { error ? <LoginAlert severity={error ? error.error ? "error" : "warning" : "error"} error={error} /> : null }
                    </Grid>
                    <Grid item xs={12}>
                        <GTIWebTextField
                            name="password"
                            type="password"
                            disabled={isLoading}
                            value={formData.password}
                            onChange={handleChange}
                            labelText="Escribe tu nueva contraseña"
                            placeholder="Escribe tu contraseña"
                            legendText={formData.passwordLegend}
                            onBlur={validatePasswords}
                            width="100%"
                            state={formData.passwordState}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GTIWebTextField
                            name="passwordConfirm"
                            type="password"
                            disabled={isLoading}
                            value={formData.passwordConfirm}
                            onChange={handleChange}
                            labelText="Vuelve a escribir tu nueva contraseña"
                            placeholder="Escribe tu contraseña"
                            legendText={formData.confirmLegend}
                            onBlur={validatePasswords}
                            width="100%"
                            state={formData.confirmState}
                        />
                    </Grid>
                </Grid>
            </>
 

    const SendRecoveryEmail =  <>  
                <Grid container spacing={1} className={classes.logo}>
                        <div className={classes.logo}>
                            <CheckCircleOutline style={{ color: "#108043", fontSize: 70 }} />
                        </div>
                        <Grid item xs={12} className={classes.logo}>
                            <div className={classes.title} style={{padding:0}}>
                                ¡Enlace enviado!
                            </div>
                            <div style={{ marginTop: 30, marginBottom: 30 }}>
                                Te hemos enviado un enlace para restablecer tu contraseña. Por favor, revisa tu correo electrónico y sigue las instrucciones.
                            </div>
                        </Grid>
                </Grid>
            </>


    const initValues = () => {
        setFormData({
            usernameEmail: "",
            userState: null,
            userLegend: "Entra con el dato que tienes registrado",
            userIcon: null,
    
            password: "",
            passwordState: null,
            passwordConfirm: "",    
            passwordLegend: "Debe contener mínimo 8 caracteres con: 1 mayúscula y 1 minúscula"
        });
    };

    return (
        <LoginLayout image={loginImage} title={"Configura empresas, ajustes y mucho más."}>
            <Grid item component={Paper} className={classes.forgotContainer}>

                <div className={classes.logo}>
                    <img src={logo} alt="Logo" className={classes.imageIcon} />
                </div>

                <Grid container className={classes.containerTitle}>
                    <div className={classes.backSpace}>
                        <GTIWebButton
                            icon={<ArrowBack className={classes.backButton} />}
                            iconPosition="start"
                            onlyIcon={true}
                            onClick={redirectLogin}
                        />
                    </div>
                    <div className={classes.title}>
                        {recoveryInfo.tile}
                    </div>
                </Grid>

                <div className={classes.paper}>
                    {
                        sendRecoveryView ? SendRecoveryEmail : (
                            <form className={classes.form} onSubmit={handleSubmit} >

                                { recoveryInfo.visible ? ChangePassword : FormRecovery }

                                <Grid container justify="center" className={classes.buttonSpace}>
                                    <GTIWebButton
                                        onlyIcon={isLoading}
                                        icon={isLoading ? <CircularProgress size={30} /> : null}
                                        disabled={isLoading}
                                        iconPosition={"end"}
                                        type={"submit"}
                                        buttonText={recoveryInfo.buttonText}
                                    />
                                </Grid>
                            </form>
                        )
                    }
                    
                </div>
            </Grid>
            
        </LoginLayout>
    )
};

export default ForgotPassword;
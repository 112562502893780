import React, { useState, useEffect  } from "react";
import { GTIWebDatePicker, GTIWebNumberField, GTIWebSelect, GTIWebTextField } from "@gti-controls/web";
import Row from '../../form/row';
import Col from '../../form/col';
import useSolicitud from "../../../hooks/useSolicitud";
import moment from "moment";
import useJavascript from "../../../hooks/useJavascripts";

export const Step1Solicitud = (statesSteps, onChange, setError, pasoConfig) => {
    const { getLista, getMotivo, getTargetObject } = useSolicitud();
    // eslint-disable-next-line
    const [currentJavascript, setCurrentJavascript] = useState(pasoConfig?.FormaObj?.JAVASCRIPTNG);
    
    //Agregar aquí todos los comboboxes de los modelos con pantallas fijas
    const [state, setState] = useState({
        tipoId: { 
            dataSource: [], 
            value: null, 
            displayedValue:"ValorNombre",
            selectedValue: "ValorNumero" 
        },
        motivoId: { 
            dataSource: [], 
            value: null,
            displayedValue: "MotivoCalNombre",
            selectedValue: "MotivoCalID"  
        }
    });
    const { evalJS } = useJavascript(state, setState);


    useEffect(() =>{
        if(statesSteps?.Atributos && statesSteps?.Consulta)
        {
           statesSteps.fechaAutorizacion = moment(new Date(statesSteps?.Atributos.EmpCalFecha)).format("YYYY-MM-DDT00:00:00.000") + "Z";
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() =>{
        if (statesSteps?.modeloID) {
            getTipos();
        }
        // eslint-disable-next-line
    }, [statesSteps.modeloID]);

    useEffect(() => {
        if ( state.tipoId.value >= 0  && state.tipoId.value != null ) {
            getMotivoSolicitud(-1);
        }

        // eslint-disable-next-line
    }, [state.tipoId.value]);

    const getTipos = async() =>{
       try {
            const listaId = 217; // Valor fijo para autorización de horas
            const tiposSolicitud = await getLista(statesSteps.modeloID, listaId);
            state.tipoId.dataSource = tiposSolicitud;
            state.tipoId.value = statesSteps?.tipoId ? statesSteps?.tipoId : 0;
            setState({...state});

            getMotivoSolicitud(state.tipoId.value);
       } catch (error) {
            setError(error);
       }
    };

    const getMotivoSolicitud = async(tipoID) =>{
        try {
            const tipo = tipoID > -1 ? tipoID : state.tipoId.value;
            const motivosSolicitud = await getMotivo(statesSteps?.modeloID, tipo)
            state.motivoId.dataSource = motivosSolicitud;
            state.motivoId.value = statesSteps?.motivoId ? statesSteps?.motivoId : 0;
            setState({...state});

            executeJavascriptNG();
        } catch (error) {
            setError(error);
        }
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        state[name].value = value;
        setState({ ...state });

        let resp = getTargetObject(e.target.name, e.target.value)
        onChange(resp);
    }

    const handleChangeHoras = (e) => {
        setError(false);
        if(parseFloat(e.target.value) >= 0.0){
            let resp = getTargetObject(e.target.name, e.target.value)
            onChange(resp);
        }
    }
    const handleChangeFecha = (e) => {
        let resp = getTargetObject(e.target.name, e.target.value)
        onChange(resp);
    }

    const handleChangeDesc = (e) => {
        let resp = getTargetObject(e.target.name, e.target.value);

        if (onChange) {
            onChange(resp);
        }
    };
    
    const executeJavascriptNG = () => {
        if ( currentJavascript ) {
            try {
                evalJS(currentJavascript);
            } catch (err) {
                console.error('error al ejecutar el JS para el setp1', err)
            }
        }
        
    }
    
    return (<Col xs={12} sm={12} md={12} lg={8} xl={8}> 
                <Row>
                    <GTIWebDatePicker
                        required={true}
                        name = "fechaAutorizacion"
                        labelText={"Fecha de autorización"}
                        onChange={handleChangeFecha}
                        value={statesSteps.fechaAutorizacion}
                    /> 
                </Row>
                <Row>
                    <GTIWebSelect
                        name="tipoId"
                        displayedValue={state?.tipoId?.displayedValue}
                        selectedValue={state?.tipoId?.selectedValue}
                        labelText="Tipo"
                        width={'100%'}
                        required={true}
                        dataSource={ state?.tipoId?.dataSource }
                        onChange={ handleChange }
                        value={ state?.tipoId?.value }
                    />
                </Row>
                <Row>
                    <GTIWebNumberField
                        name="horas"
                        limits={[0, 256]}
                        maxLength={6}
                        allowNegative={false}
                        labelText="Horas Autorizadas"
                        width={150}
                        onBlur = { handleChangeHoras }
                    />
                </Row>
                <Row>
                    <GTIWebSelect
                        name="motivoId"
                        displayedValue={state?.motivoId?.displayedValue}
                        selectedValue={state?.motivoId?.selectedValue}
                        labelText="Motivo"
                        width={'100%'}
                        required={true}
                        dataSource={ state?.motivoId?.dataSource }
                        onChange={ handleChange }
                        value={ state?.motivoId?.value }
                    />
                </Row>
                <Row>
                    <GTIWebTextField
                        name={"motivoDesc"}
                        labelText={"Descripción del motivo"}
                        isTextArea={true}
                        rows={5}
                        width={'100%'}
                        onChange={handleChangeDesc}
                        maxLength={255}
                    />
                </Row>
           </Col>)
} 

export default Step1Solicitud;
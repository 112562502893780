import React, { useEffect, useState, useContext } from 'react';
import { Grid , GTIWebTextField, GTIWebSelect } from "@gti-controls/web";
import { GTIWebRadio } from  "@gti-controls-v2/web-core";
import moment from "moment";
import useSolicitud from '../../hooks/useSolicitud';
import { SettingsContext } from '../../providers/settings';
import Row from '../form/row'
import Col from '../form/col'
import homeStyles from "../../common/styles/Home";

export const ResumenProceso = (statesSteps, onChange, setError) => {
    const homeStyle = homeStyles();
    const { postRuteos, getTargetObject } = useSolicitud();
    const { handleLoadingContainerChange } = useContext(SettingsContext);
    const [rutas, setRutas] = useState([]);
    const [selectRadio, setSelectRadio] = useState(false);
    const [usuarioSiguiente, setUsuarioSiguiente] = useState(0);
    const [rutaConfig, setRutaConfig] = useState({
        unaRuta: true,
        siguientePaso: '',
        controlRuta: {
            usuarioResponsable: {
                usuarioNombre: ''
            }
        }
    });

    useEffect(() => { 
        if (statesSteps.currentStep === 3 || statesSteps.stepName === 'Resumen') {
            getRuteosConfig();
        }
        // eslint-disable-next-line
    }, [statesSteps.currentStep]);

    const filterEmp = (empleados) => {
        return empleados.filter(empleado => parseFloat(empleado.AutCalHoras) > 0.0);
    };

    const getRuteosConfig = async () => {
        try {
            handleLoadingContainerChange(true);
            const empleados = filterEmp(statesSteps.Empleados);
            const cargo = {
				REGISTROS: empleados,
				ATRIBUTOS: {
			    	EmpCalFecha: moment.utc(statesSteps.fechaAutorizacion).format('YYYY/MM/DD'),
					MotivoCalID:  statesSteps.motivoId,
					MotivoCalClase: statesSteps.tipoId,
			    	MotivoCalPrevia: 1
				}
			};

            let ProcID = statesSteps?.ProcID ? statesSteps.ProcID : 0;

            const rutasInfo = {
                modeloID: statesSteps.modeloID,
                taskID: statesSteps.taskID,
                ProcID: ProcID,
                RuteoID: 0,
                TareaID: statesSteps?.TareaID ? statesSteps.TareaID : 0,
                CARGO: cargo
            };
            
            const ruta = await postRuteos(rutasInfo);
            setRutaConfig(ruta[0]);
            setRutas(ruta);

            setSelectRadio(ruta[0]?.unaRuta)

            let resp = getTargetObject("rutaConfigura", ruta);

            if (onChange) {
                onChange(resp);
            }
            handleLoadingContainerChange(false);
        } catch (error) {
            setError(error.message);
            handleLoadingContainerChange(false);
        }
    };

    const handleOnChangeObserva = (e) => {
        let resp = getTargetObject(e.target.name, e.target.value);

        if (onChange) {
            onChange(resp);
        }
    };

    const handleChangeRadio = async (e) => {
        try {
            handleLoadingContainerChange(true);

            const empleados = filterEmp(statesSteps.Empleados);
            const cargo = {
                REGISTROS: empleados,
                ATRIBUTOS: {
                    EmpCalFecha: moment.utc(statesSteps.fechaAutorizacion).format('YYYY/MM/DD'),
                    MotivoCalID:  statesSteps.motivoId,
                    MotivoCalClase: statesSteps.tipoId,
                    MotivoCalPrevia: 1
                }
            };

            let ProcID = statesSteps?.ProcID ? statesSteps.ProcID : 0;

            const rutasInfo = {
                modeloID: statesSteps.modeloID,
                taskID: statesSteps.taskID,
                ProcID: ProcID,
                RuteoID: parseInt(e.target.value),
                TareaID: statesSteps?.TareaID ? statesSteps.TareaID : 0,
                CARGO: cargo
            };

            const ruta = await postRuteos(rutasInfo);

            setRutaConfig(ruta[0]);
            let resp = getTargetObject("rutaSeleccionada", ruta[0]);
            onChange(resp);
            setSelectRadio(true);
            handleLoadingContainerChange(false);
        } catch (error) {
            setError(error.message);
            handleLoadingContainerChange(false);
        }
    };

    const handleChangeSelect = (e) => {
        let rutaUsuarioSelected = JSON.parse(JSON.stringify(statesSteps.rutaSeleccionada));
        rutaUsuarioSelected.controlRuta.usuarioResponsable = { ...JSON.parse(JSON.stringify(rutaConfig.controlRuta.usuarioResponsable.filter(usuario => usuario.usuarioId === e.target.value)[0])) };

        let resp = getTargetObject("rutaSeleccionada", rutaUsuarioSelected);
        onChange(resp);
        setUsuarioSiguiente(e.target.value);
    };

    return (
        <>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid xs={12} className={ !rutaConfig?.unaRuta ? '' : homeStyle.hidden } >
                    <Row xs={12} >
                        <GTIWebRadio
                            name="ruta"
                            labelText="¿Qué acción desea realizar?"
                            dataSource={rutas}
                            selectedValue="RuteoID"
                            displayedValue="siguientePaso"
                            column={true}
                            id="RdbtnRutas"
                            onChange={handleChangeRadio}
                        />
                    </Row>
                </Grid> 
                {                     
                    rutaConfig?.unaRuta ?
                        <Row item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <GTIWebTextField
                                labelText={"Siguiente Tarea :"}
                                value={rutaConfig?.siguientePaso}
                                readonly={true}
                            />
                        </Row>
                    : <></>

                }
                <Row item xs={12} sm={12} md={8} lg={8} xl={8}>
                    {
                        rutaConfig?.controlRuta.usuarioResponsable.length > 1 ?
                        (
                            <GTIWebSelect
                                labelText={"Enviar a :"}
                                name={'usuarioSiguiente'}
                                width={"100%"}
                                displayedValue={"usuarioNombre"}
                                selectedValue={"usuarioId"}
                                placeholder = {"Elige una opción"}
                                disabled={!rutaConfig?.controlRuta?.usuarioResponsable}
                                dataSource={rutaConfig?.controlRuta?.usuarioResponsable}
                                onChange={handleChangeSelect}
                                value={usuarioSiguiente}
                            />
                        ) : (
                            rutaConfig?.controlRuta.enviarAVisible ?
                                <GTIWebTextField
                                    labelText={"Enviar a :"}
                                    value={selectRadio ? rutaConfig?.controlRuta?.usuarioResponsable?.usuarioNombre : ''}
                                    readonly={true}
                                />
                            : null
                        )
                    }
                    
                </Row>
                <Row item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <GTIWebTextField
                        name={"observaciones"}
                        labelText={"Observaciones"}
                        isTextArea={true}
                        rows={10}
                        width={'100%'}
                        onChange={handleOnChangeObserva}
                        maxLength={1000}
                    />
                </Row>
            </Col>
        </>
    )
};

export default ResumenProceso;
import React, { useState, useContext , useEffect} from 'react';
import logo from "../../images/logo-masorden-gris-small.svg";
import { AppBar, Toolbar, GTIWebButton, Typography, Link, MenuOpen, Person } from "@gti-controls/web";
import homeStyles from "../../common/styles/Home";
import { AuthContext } from "../../providers/auth";
import { SettingsContext } from '../../providers/settings';
import AccountSelector from "../selector";
import { useHistory, useLocation } from "react-router-dom";
import useSolicitud from "../../hooks/useSolicitud";

const Header = () => {
  const classes = homeStyles();
  const { state: authContext, dispatch } = useContext(AuthContext);
  const [usuarioWFId, setUsuarioWFId] = useState(0);
  const {
    isSelectingAccount,
    handleDrawerToggle,
    settings,
  } = React.useContext(SettingsContext);
  const {handleLoadingContainerChange } = useContext(SettingsContext);
  const history = useHistory();
  const { getDerechos , getUsuarioOut , loginWF} = useSolicitud();
  const VERBO_FUERAOFICINA = 2346;

  const login = async () => {
    handleLoadingContainerChange(true);
    const isLogin = authContext.user.usuarioIdWF;

    if (isLogin > 0) {
    } else {
        const tokens = JSON.parse(localStorage.getItem('authTokens'));
        const username = authContext.user?.emailTestWFN ? authContext.user.emailTestWFN : authContext.user.userLogin;
        const despachoID = settings.accountSelected.despachoId;
        
        const response = await loginWF(username, despachoID, tokens.AccessToken);

        if (response) {
            let user = JSON.parse(localStorage.getItem('user'));
            user = {
                ...user,
                sesionIdWF: response.sesionIdWF,
                usuarioIdWF: response.usuarioIdWF
            };
            await localStorage.setItem("user", JSON.stringify(user));
            await setUsuarioWFId(response.usuarioIdWF);
            handleLoadingContainerChange(false);
            return true;
        } else {
          handleLoadingContainerChange(false);
        }
    }

  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {!isSelectingAccount ? (
          <div className={classes.menuButton}>
            <GTIWebButton
              onlyIcon={true}
              onClick={() => handleDrawerToggle()}
              icon={<MenuOpen />} />
          </div>
        ) : null}
        <div className={classes.logo}>
          <img src={logo} alt="Logo" className={classes.imageIcon} />
        </div>
        <AccountSelector/>
        <div className={classes.spacer}/>
        <div className={classes.user}>
          <div className={classes.userIcon}>
            <Person className={classes.userIcon} />
          </div>
          <Typography noWrap className={classes.userTitle}>
          <Link color="inherit" onClick={async () =>{
            handleLoadingContainerChange(true);
            await login();
            const derechos = await getDerechos(VERBO_FUERAOFICINA);
            if(derechos[''])
              {
                handleLoadingContainerChange(true);
                const usuarioOut = await getUsuarioOut();

                handleLoadingContainerChange(false);
                history.push({
                  pathname: '/CuentaConfigura',
                  customTitle: "Configuración de la cuenta",
                  state: {
                    usuario:usuarioOut,
                    usuarioWFId:usuarioWFId ,
                    sub: {
                      verboID: VERBO_FUERAOFICINA,
                    },
                  }
                });
                
              }
          

          }}> {authContext.user.username}</Link>
          </Typography>
        </div>
        <div className={classes.logout}>
          <Link color="inherit" onClick={() => dispatch({
            type: "LOGOUT",
            signOff: true,
          }) }>Salir</Link>
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default Header;
import React from 'react';
import { Grid } from "@gti-controls/web";


const Col = (props) => {
  return (
      <Grid item {...props}>
        {props.children}
      </Grid>
  )
};

export default Col;
import { useEffect } from "react";

const useJavascript = (state, setState) => {

    useEffect(() => {
        window['removeMasOrdenFijoComboboxOptionByTexto'] = removeMasOrdenFijoComboboxOptionByTexto;
        // eslint-disable-next-line
    }, [])

    //#region Funciones para ejecutar desde los JS de Workflow
    const removeMasOrdenFijoComboboxOptionByTexto = (comboboxName, opcionTexto) => {
        try{
            state[comboboxName].dataSource = state[comboboxName].dataSource.filter(function( opcion ) {
                return opcion[state[comboboxName].displayedValue] !== opcionTexto;
            });

            setState({...state});
        }catch(err) {
            console.error('No se pudo ejecutar removeMasOrdenFijoComboboxOptionByTexto', err)
        }
    };
    //#endregion

    const evalJS = (js) => {
        if (js) {
            try {
                /* eslint-disable no-new-func */
                const func = new Function(js);
                func();
            } catch (err) {
                console.error('Error al ejecutar el JS', err);
            }
        }
    };

    return {
        evalJS
    };
    
};

export default useJavascript;
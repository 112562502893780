import React from 'react';
import homeStyles from "./styles";

const Label = ({text, customStyle}) => {
  const classes = homeStyles();

  return (
    <label className={classes.lab} style={customStyle}>{text}</label>
  )
}
export default Label;
import React, { useState, useEffect  } from "react";
import { GTIWebNumberField, GTIWebDataGrid, Grid } from "@gti-controls/web";
import useSolicitud from "../../../hooks/useSolicitud";

export const Step2Solicitud = (statesSteps, onChange,setError) => {
    const { getEmpleados, getTargetObject} = useSolicitud();
    const [ dataEmpleados, setDataEmpleados ] = useState([]);

    useEffect(() =>{
        if (statesSteps?.horas) {
            getEmpleadosHandler();
        }
        // eslint-disable-next-line
    }, [statesSteps?.horas])

    const getEmpleadosHandler = async() =>{
        try {
            let empleados;
            if(statesSteps?.Empleados.length > 0 && statesSteps?.consulta)
            {
                if(parseInt(statesSteps?.horas) > 0 &&  dataEmpleados.length > 0)
                {
                    empleados =  dataEmpleados.map(function(registro) { registro.AutCalHoras = statesSteps?.horas; return registro;})
                }else
                {
                    empleados =  statesSteps?.Empleados;
                }
            }else
            {
                empleados = await getEmpleados(statesSteps.modeloID, statesSteps.horas);
            }

            setDataEmpleados(empleados);
            if(onChange){
                let resp = getTargetObject("Empleados", empleados);
                onChange(resp);
            }
        } catch (error) {
            setError(error);
        }
    };


    const columns = [
        { domain: "folio_id", field: 'EmpNumero', headerName: 'Número', width: 150, headerAlign: 'center', align: 'center' },
        { domain: "nombre", field: 'PersonaName', headerName: 'Nombre', width: 300, headerAlign: 'left', align: 'left' },
    ];

    const handleChangeHoras = (id, value) => {
        let empleados = dataEmpleados;
        let index = empleados.findIndex(r => r.PersonaID === id);
        empleados[index].AutCalHoras = value;
        setDataEmpleados(empleados);

        if(onChange){
            let resp = getTargetObject("Empleados", empleados);
            onChange(resp);
        }
    }

    const columnsWithSelectButton = () => {
        const cols = [...columns];
        cols.push({
            field: "AutCalHoras",
            headerName: "Horas",
            headerAlign: 'right',
            align: 'right',
            width: 120,
            renderCell: (record) => 
                <GTIWebNumberField
                    name="AutCalHoras"
                    width={100}
                    limits={[0, 256]}
                    id={record.row.PersonaID}
                    value={record.row.AutCalHoras}
                    maxLength={6}
                    allowNegative={false}
                    onChange = { (e) => { handleChangeHoras(record.row.PersonaID, e.target.value)} }
                />
        });
        return cols;
    };

    return (
        <Grid>
            <GTIWebDataGrid
                rows={dataEmpleados}
                getRowId={(r) => r.PersonaID}
                columns= {columnsWithSelectButton()}
                showSearchTextField={true}
                disableSelectionOnClick
                headerHeight={36}
                rowHeight={36}
            />
        </Grid>
    )
}

export default Step2Solicitud;
import React, { useState, useEffect } from "react";
import {Grid, GTIWebTextField, GTIWebGridPopper } from "@gti-controls/web";
import Row from '../../form/row';
import Col from '../../form/col';
import useSolicitud from "../../../hooks/useSolicitud";
import useFormat from "../../../hooks/useFormat";
import GridView from "../../../components/gridview";
import homeStyles from "../../../common/styles/Home";
import Label from "../../label";

export const Step1Autoriza = (statesSteps, onChange, setError) => {
    const { getDataTareaDetalle } = useSolicitud();
    const hStyles = homeStyles();

    const { emptyNumberFormatter } = useFormat();
    const [empleados, setEmpleados] = useState([]);
    const [fechaAutoriza, setfechaAutoriza] = useState('');
    const [motivoCalendario, setMotivoCalendario] = useState('');
    const [tipoMoivoCal, setTipoMoivoCal] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [totales, setTotales] = useState([]);
    const [motivoDesc, setMotivoDesc] = useState("");

    useEffect(() => {
        getTareaDetalle();
        // eslint-disable-next-line
    }, []);
    
    useEffect(() =>{
        if (statesSteps?.modeloID) {

        }
        // eslint-disable-next-line
    }, [statesSteps.modeloID]);

    const getTareaDetalle = async () => {
        setIsLoading(true);
        const data = await getDataTareaDetalle(statesSteps?.TareaID);
        setEmpleados(data.Empleados);
        setfechaAutoriza(data.FechaAutorizacion);
        setMotivoCalendario(data.MotivoCalNombre);
        setTipoMoivoCal(data.TipoMotivo);
        setMotivoDesc(data.MotivoDesc);

        const totalHoras = data.Empleados.reduce((total, item) => {
            return total + parseFloat(item.AutCalHoras);
        }, 0);
        setTotales(totalHoras);
        setIsLoading(false);
    };
    
    const columns = [
        { field: 'EmpNumero', headerName: 'Numero', type: 'number', width: 120, valueFormatter: emptyNumberFormatter },
        { field: 'PersonaName', headerName: 'Nombre',  width: 250, showPopper: true },
        { field: 'AutCalHoras', headerName: 'Horas', type: 'number', width: 100 }
      ];
   
    return (
    <Grid>
        <Grid xs={12} className={hStyles.Alertoutlined} >
            <Row xs={12} >
                <Col xs={4} md={3} lg={2} xl={2}>
                    <GTIWebTextField
                        labelText={"Fecha de Autorización"}
                        value={fechaAutoriza}
                        readonly={true}
                    />
                </Col>
                <Col xs={4} md={3} lg={2} xl={2}>
                    <GTIWebTextField
                        labelText={"Motivo"}
                        value={motivoCalendario}
                        readonly={true}
                    />
                </Col>
                <Col xs={4} md={3} lg={2} xl={2}>
                    <Label text={'Descripción del motivo'}/>
                    <Row xs={2} md={3} lg={6} xl={6} >
                        <GTIWebGridPopper value={motivoDesc}/>
                    </Row>
                </Col>
                <Col xs={4} md={3} lg={2} xl={2}>
                    <GTIWebTextField
                        labelText={"Tipo"}
                        value={tipoMoivoCal}
                        readonly={true}
                    />
                </Col>
                <Col xs={4} md={3} lg={2} xl={2}>
                    <GTIWebTextField
                        labelText={"Total Horas Autorizadas"}
                        value={totales}
                        readonly={true}
                    />
                </Col>
            </Row>
        </Grid>

        <GridView
            dataSource={empleados}
            columns={columns}
            getRowId={(r) => r.EmpNumero}
            showCrudButtons={false}
            showSearchTextField={true}
            showMoreActionsSelector={true}
            showBasicActionButtons={true}
            pageSize={10}
            isLoadingDataSource={isLoading}
        />

    </Grid>);
};

export default Step1Autoriza;

import { makeStyles } from "@gti-controls/web";

const homeStyles = makeStyles(theme => ({
  lab: {
    color: '#373740',
    fontSize: 14,
    transform: 'none !important',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: 1.43,
    fontStretch: 'normal',
    letterSpacing: '0.25px',
    paddingTop: '16px',
    paddingBottom: '8px'
  }
}));

export default homeStyles;
import React from 'react';
import { Grid } from "@gti-controls/web";

const Row = (props) => {

	const checkProps = () => {
		const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
		return Object.keys(props).some(item => sizes.includes(item));
	};

	const isSingleColumn = () => {
		return props.children && props.children.length === 1;
	};

	return (
		<Grid container spacing={2}>
			<Grid
				item
				xs={checkProps() ? props.xs : 12}
				sm={checkProps() ? props.sm : 12}
				md={checkProps() ? props.md : 6}
				lg={checkProps() ? props.lg : 5}
				xl={checkProps() ? props.xl : 4}
				container={isSingleColumn()}>
				{isSingleColumn() ?
					props.children :
					<Grid item container spacing={2}>
						{props.children}
					</Grid>}
			</Grid>
		</Grid>
	)
};

export default Row;
import { http } from "../../common/services/http-service";
import config from "../../config";
import step1 from "../../components/solicitud-forms/step1";
import step2 from "../../components/solicitud-forms/step2";
import step1Autoriza from "../../components/solicitud-forms/step1Autoriza";
import ResumenProceso from "../../components/resumen-wfn";

const useSolicitud = (setError) =>{

    const getSteps = () => {
        return [
            {
                StepNo: 1,
                StepName : "Capturar tipo y motivo",
                Form : step1
            },
            {
                StepNo: 2,
                StepName : "Anotar horas",
                Form : step2
            },
            {
                StepNo: 3,
                StepName : "Resumen",
                Form : ResumenProceso
            }
        ];
    };

    const getStepsAutoriza = () => {
        return [
            {
                StepNo: 1,
                StepName : "Información",
                Form : step1Autoriza
            },
            {
                StepNo: 2,
                StepName : "Resumen",
                Form : ResumenProceso
            }
        ];
    };

    const getTargetObject = (name,value) => {
        return {
            target: {
                value: value,
                name:name
            }
        };
    };

    const getProcesoSolicitud = async (id,taskID,TareaID) => {
        try{
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/proceso?modeloID=${id}&taskID=${taskID}&tareaID=${TareaID}`);
            return response.data;
        } catch (err){
            return "No se pudo obtener información del proceso";
        }
    };
    
    
    const getModelosMOWF = async (DespachosID) => {
        try{
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/modelos?despachoID=${DespachosID}`);
            return response.data;
        } catch (err){
            return "No se pudo obtener información de los modelos";
        }
    }

    const getHeader = async (ModeloID, TaskID) => {
        try{
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/header?modeloID=${ModeloID}&TaskID=${TaskID}`);
            return response.data;
        }catch(err){
            return "No se pudo obtener el header";
        }
    };

    const getLista = async (ModeloID,id) => {
        try{
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/lista?modeloID=${ModeloID}&listaFijaId=${id}`);
            return response.data;
        }catch(err){
            return "No se pudo obtener la lista de Tipos";
        }
    };

    const getMotivo = async (ModeloID, id) =>{
        try{
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/motivo?modeloID=${ModeloID}&clase=${id}`);
            return response.data;
        }catch(err){
            return "No se pudo obtener el listado de motivos";
        }
    };

    const getEmpleados = async (ModeloID, HorasDefault) => {
        try {
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/empleados?modeloID=${ModeloID}&horasDefault=${HorasDefault}`);
            return response.data;
        } catch (error) {
            return "No se pudo obtener los empleados";
        }
    };

    const postRuteos = async (rutasInfo) => {
        try {
            cleanError();
            const response = await http('post', `${config.ADMIN_API_URL}wf-requests-admin/ruteos`, rutasInfo);
            return response?.data;
        } catch (error) {
            throw Error("No se pudo obtener las rutas");
        }
    };

    const postXML = async (ProcCargo) => {
        try {
            const response = await http('post', `${config.ADMIN_API_URL}wf-requests-admin/createXML`, ProcCargo );
            return response.data;
        } catch (error) {
            return "No se pudo crear XML cargo";
        }
    };

    const postSolicitud = async (Solicitud) => {
        try {
            const response = await http('post', `${config.ADMIN_API_URL}wf-requests-admin/solicitud`, Solicitud );
            return response.data;
        } catch (error) {
            return "No se pudo crear Solicitud";
        }
    };

    const getFiltersStatus = async (id) => {
        try {
            const response = await http('get', `${config.API_URL}catalogs/1/lists/${id}`);
            return response.data;
        } catch (error) {
            return "No se pudo obtener el listado de estatus";
        }
    }

    const getFiltersTareas = async (id) => {
        try {
            const response = await http('get', `${config.API_URL}catalogs/1/lists/${id}`);
            return response.data;
        } catch (error) {
            return "No se pudo obtener el listado de tareas";
        }
    }

    const getProcesoLista = async () => {
        try {
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/procesos`);
            return response.data;
        } catch (error) {
            return "No se pudo obtener el listado de procesos";
        }
    }

    const getEmpresas = async () => {
        try {
            const response = await http('get', `${config.ADMIN_API_URL}empresas/despachos`);
            return response.data;
        } catch (error) {
            return "No se pudo obtener el listado de empresas";
        }
    }

    const getDataProcesos  = async (empresaId, estatusProcId, procesoId, startDate, endDate) => {
        try {
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/procesos?clienteID=${empresaId}&status=${estatusProcId}&modeloID=${procesoId}&inicial=${startDate}&final=${endDate}`);
            return response.data;
        } catch (error) {
            throw error?.response?.data?.detalle?.internos; 
        }
    }

    const getDataTareas = async (EmpresaID, StatusID, TareaID, startDate, endDate) => {
        try {
            const response =  await http('get', `${config.ADMIN_API_URL}wf-requests-admin/tareas?clienteID=${EmpresaID}&status=${StatusID}&modeloID=${TareaID}&inicial=${startDate}&final=${endDate}`);
            return response.data;
        } catch (error) {
            throw error?.response?.data?.detalle?.internos; 
        }
    }


    const getDataTareaDetalle = async (TareaID) => {
        try {
            const response =  await http('get', `${config.ADMIN_API_URL}wf-requests-admin/TareaDetalle?TareaID=${TareaID}`);
            return response.data;
        } catch (error) {
            return "No se pudo obtener el Detalle de tareas"
        }
      };
    
    const getAcciones = async (VerboID, sesionId) =>{
        try {
            const response = await http('get', `${config.API_URL}rights/verbos/${VerboID}/acciones/` + sesionId)
            return response.data
        } catch (error) {
            
        }
    }

    const getDerechos = async (VerboID) =>{
        try {
            const response = await http('get', `${config.API_URL}rights/verbos/${VerboID}/derechos`)
            return response.data
        } catch (error) {
            
        }
    }


    const getModelosMO = async (despachoID) => {
        try {
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/procesosmo?DespachoID=${despachoID}`)
            return response.data
        } catch (error) {
            throw error?.data?.detalle?.internos; 
        }
    }


    const getUsuariosDelega = async (TareaId) => {
        try {
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/UsuariosDelegar?TareaId=${TareaId}`)
            return response.data
        } catch (error) {
            throw error?.data?.detalle?.internos; 
        }
    }

    const getTareaConsulta= async (TareaId) => {
        try {
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/TareaConsulta?TareaId=${TareaId}`)
            return response.data
        } catch (error) {
            throw error?.data?.detalle?.internos; 
        }
    }

    const postDelega = async (Solicitud) => {
        try {
            const response = await http('post', `${config.ADMIN_API_URL}wf-requests-admin/DelegaTarea`, Solicitud );
            return response.data;
        } catch (error) {
            return "No se pudo crear Solicitud";
        }
    };


    const postAutoriza = async (Solicitud) => {
        try {
            const response = await http('post', `${config.ADMIN_API_URL}wf-requests-admin/Autoriza`, Solicitud );
            return response.data;
        } catch (error) {
            return "No se pudo crear Solicitud";
        }
    };

    
    const getProcesoPasos = async (ProcID) => {
        try{
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/pasos?ProcID=${ProcID}`);
            return response.data;
        } catch (err){
            return "No se pudo obtener información del proceso";
        }
    };

    const getDataProcesoDetalle = async (ProcID) => {
        try{
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/detalle?ProcID=${ProcID}`);
            return response.data;
        } catch (err){
            return "No se pudo obtener información del proceso";
        }
    };
    
    const loginWF = async (username, despachoId, access_token) => {
        try {
            cleanError();
            const body = {
                username: username,
                despachoId: despachoId,
                access_token: access_token
            };

            const response = await http('post', `${config.API_URL}auth/loginWF`, body, null, true);

            return response;
        } catch (error) {
            console.error("[error loginWF]:", error);
        }
    };

    const postVlida = async (valida) => {
        try {
            const response = await http('post', `${config.ADMIN_API_URL}wf-requests-admin/valida-captura`, valida );

            return response.data;
        } catch (error) {
            return "No se pudo validar Solicitud";
        }
    };

    const getTareaDetalleByGUID = async (guid) => {
        try{
            cleanError();
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/TareaGUID?GUID=${guid}`);
            return response.data;
        } catch (err){
            return { exitoso: false, erroMsg: err?.response?.data?.mensajeError ? err?.response?.data?.mensajeError : "No se pudo obtener detalle de Tarea" };
        }
    };

    const getUsuariosLookup = async () => {
        try {
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/usuariosLookup`)
            return response.data
        } catch (error) {
            throw error?.data?.detalle?.internos; 
        }
    }


    const getUsuarioOut = async () => {
        try {
            const response = await http('get', `${config.ADMIN_API_URL}wf-requests-admin/usuarioOut`)
            return response.data
        } catch (error) {
            throw error?.data?.detalle?.internos; 
        }
    }

   
    const cleanError = () => {
        try {
            if (setError) {
                setError("");
            }
        } catch {
            
        }

    };

    return {
        getSteps,
        getProcesoSolicitud,
        getHeader, 
        getLista,
        getMotivo,
        getEmpleados,
        getTargetObject,
        postXML,
        postRuteos,
        postSolicitud,
        getModelosMOWF,
        getFiltersStatus,
        getFiltersTareas,
        getProcesoLista,
        getEmpresas,
        getDataProcesos,
        getDataTareas,
        getStepsAutoriza,
        getDataTareaDetalle,
        getAcciones,
        getDerechos,
        getModelosMO,
        getUsuariosDelega,
        getTareaConsulta,
        postDelega,
        postAutoriza,
        getProcesoPasos,
        getDataProcesoDetalle,
        loginWF,
        postVlida,
        getTareaDetalleByGUID,
        getUsuariosLookup,
        getUsuarioOut
    };
    
}

export default useSolicitud;
